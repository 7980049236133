<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">商户通知</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="商户订单:">
          <el-input v-model="p.outTradeNo" style="width: 230px;" clearable></el-input>
        </el-form-item>
        <el-form-item label="系统订单:">
          <el-input v-model="p.transactionId" style="width: 230px;" clearable></el-input>
        </el-form-item>
        <br v-if="this.sa_admin.role == 2" />
        <el-form-item v-if="this.sa_admin.role == 2" label="商户昵称:">
          <el-input v-model="p.nickName" clearable style="width: 230px;"></el-input>
        </el-form-item>
        <el-form-item v-if="this.sa_admin.role == 2" label="商户号:">
          <el-input v-model="p.mchId" clearable style="width: 230px;"></el-input>
        </el-form-item>
        <br>
        <!-- <el-form-item label="时间查询:">
          <el-date-picker v-model="p.startTime" type="date" value-format="yyyy-MM-dd" placeholder="开始日期"></el-date-picker>
          -
          <el-date-picker v-model="p.stopTime" type="date" value-format="yyyy-MM-dd" placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <br /> -->
        <el-form-item label="订单状态:">
          <el-select size="mini" v-model="p.status">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="通知成功" :value="1"></el-option>
            <el-option label="通知失败" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="min-width: 0px">
          <el-button plain type="primary" icon="el-icon-search" @click="
            p.page = 1;
          f5();
          ">查询</el-button>
          <el-button plain type="success" icon="el-icon-refresh" @click="
            p.page = 1;
          f5();">刷新</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="c-title">数据列表</div> -->
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="商户订单号" prop="id" width="250px">
        </el-table-column>
        <el-table-column label="系统订单号" prop="orderId" width="220px">
        </el-table-column>
        <el-table-column label="通知状态" prop="amount" min-width="150px">
          <template slot-scope="s">
            <el-tag v-if="s.row.state == 2" type="danger" size="mini">通知失败</el-tag>
            <el-tag v-if="s.row.state == 1" type="success" size="mini">通知成功</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdAt" min-width="135px"></el-table-column>
        <el-table-column label="最后通知时间" prop="lastNotifyTime" min-width="135px"></el-table-column>
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" plain icon="el-icon-view" @click="get(s.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
      <NoticeDetails ref="notice-details"></NoticeDetails>
    </div>
  </div>
</template>

<script>
import NoticeDetails from "./notice-details.vue";
export default {
  components: { NoticeDetails },
  data() {
    return {
      p: {
        // 查询参数
        status: null,
        outTradeNo: "",
        orderId: "",
        transactionId: "",
        subTransactionId: "",
        nickName: "",
        mchId: "",
        codeMchId: "",
        startTime: null, // 本月一号
        stopTime: null, // 本月当日
        payType: null,
        page: 1,
        limit: 10,
      },
      b: {
        // 查询参数
        status: null,
        outTradeNo: "",
        transactionId: "",
        page: 1,
        limit: 10,
      },
      dataCount: 0,
      dataList: [],
      rowCenter: {
        "text-align": "center",
        "width": "50%"
      },
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v1/merchant-notice/list",
        this.p,
        function (res) {
          this.dataList = res.data.content; // 数据
          this.dataCount = res.data.totalElements; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    // 查看
    get: function (data) {
      if (window.matchMedia("(max-width: 750px)").matches) {
        var str = "<div>";
        str += "<p>商户订单：" + data.id + "</p>";
        str += "<p>系统订单：" + data.orderId + "</p>";
        str += "<p>商户号：" + data.mchId + "</p>";
        str += "<p>商户昵称：" + data.mchName + "</p>";
        str += "<p>通知次数：" + data.notifyCount + "</p>";
        str += "<p>最大通知次数：" + data.notifyCountLimit + "</p>";
        if (data.state == 1) {
          str += "<p>订单状态：通知成功</p>";
        } else if (data.state == 2) {
          str += "<p>订单状态：通知失败</p>";
        }
        str += "<p>创建时间：" + data.createdAt + "</p>";
        str += "<p>最后通知时间：" + data.lastNotifyTime + "</p>";
        str += "<p>花费时间：" + data.spend + "</p>";
        str += "<p>通知地址：" + data.url + "</p>";
        str += "<p>响应结果" + data.respond + "</p>";
        str += "</div>";
        this.sa.alert(str);
      } else {
        this.$refs["notice-details"].open(data);
        this.drawerVisible = true;
      }
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style></style>

<template>
  <el-dialog top="6vh" title="通知详情" :append-to-body="true" :visible.sync="drawerVisible" min-size="48%" size="48%" direction="rtl">
    <div class="vue-box" style=" background-color:#ffffff;">
      <el-descriptions :colon='false' :contentStyle="rowCenter" :column="2" border size="mini">
        <el-descriptions-item label="商户订单:" :span="3"><el-tag type="warning" size="mini">{{details.id}}</el-tag></el-descriptions-item>
        <el-descriptions-item label="系统订单:" :span="3">{{details.orderId}}</el-descriptions-item>
        <el-descriptions-item label="商户号:" :span="3">{{details.mchId}}</el-descriptions-item>
        <el-descriptions-item label="商户昵称:" :span="3">{{details.mchName}}</el-descriptions-item>
        <el-descriptions-item label="通知状态:" :span="3">
          <el-tag v-if="details.state == 2" type="danger" size="mini">通知失败</el-tag>
          <el-tag v-if="details.state == 1" type="success" size="mini">通知成功</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="通知次数:" :span="3">
          <el-tag type="info" size="mini">{{details.notifyCount}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="创建时间:" :span="3">{{details.createdAt}}</el-descriptions-item>
        <el-descriptions-item label="最后通知时间:" :span="3">{{details.lastNotifyTime}}</el-descriptions-item>
        <el-descriptions-item label="花费时间:" :span="3">
          <el-tag type="warning" size="mini">{{details.spend}} ms</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="通知地址:" :span="3">{{details.url}} </el-descriptions-item>
        <el-descriptions-item label="响应结果:" :span="3"> {{details.respond}} </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      drawerVisible: false,
      dataCount: 0,
      details: {
        id: "",
        orderId: "",
        mchId: "",
        mchName: "",
        state: null,
        notifyCount: "",
        createdAt: "",
        lastNotifyTime: "",
        spend: "",
        url: "",
        respond: "",
      },
      rowCenter: {
        // "text-align": "center",
        "width": "85%",
        'word-break': 'break-all'
      },
    };
  },
  methods: {
    // 数据刷新
    open: function (data) {
      this.details = data;
      this.drawerVisible = true;
    },
    // 查看
    get: function () {

    },
  },
  created: function () {
  },
};
</script>